import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState<boolean>()
  const { devices } = useTheme()
  useEffect(() => {
    const mediaQuery = matchMedia(`(max-width: ${devices.desktop - 1}px)`)
    const onChange = (e: MediaQueryListEvent) => {
      setIsTablet(e.matches)
    }
    mediaQuery.addEventListener('change', onChange)
    setIsTablet(mediaQuery.matches)
    return () => {
      mediaQuery.removeEventListener('change', onChange)
    }
  }, [devices.desktop])
  return isTablet
}
