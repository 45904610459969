import { useCallback, useEffect, useState } from 'react'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Howl } from 'howler'
import { ThemeProvider } from 'styled-components'

import { useViewportHeight } from '../hooks/useViewportHeight'
import { SoundContext } from '../contexts/SoundContext'
import { MemeManifestoTheme } from '../theme/theme'
import { AppWrapper } from './AppWrapper'

export const App: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  useViewportHeight()
  const [soundEnabled, setSoundEnabled] = useState<boolean | null>(null)

  useEffect(() => {
    const prevChoice = localStorage.getItem('sound')
    if (prevChoice !== null) {
      setSoundEnabled(prevChoice === 'true')
    }
  }, [])

  const newSound: (src: string) => Howl = useCallback(src => {
    const howl = new Howl({
      src,
      html5: true,
      preload: 'metadata' as const,
      autoplay: false,
      loop: true,
      volume: 0,
      onunlock: () => {
        setSoundEnabled(prevSoundEnabled => {
          return prevSoundEnabled === null ? true : prevSoundEnabled
        })
      },
      onfade: () => {
        if (howl.volume() === 0) {
          howl.stop()
        }
      },
    })
    return howl
  }, [])

  const fadeIn = useCallback(
    (howl: Howl, duration = 7000) => {
      if (soundEnabled) {
        const volume = howl.volume()
        howl.fade(
          +volume.toFixed(2),
          1,
          Math.round(duration - volume * duration)
        )
        howl.play()
      }
    },
    [soundEnabled]
  )

  const fadeOut = useCallback(
    (howl: Howl, duration = 7000) => {
      if (soundEnabled && howl.playing()) {
        const volume = howl.volume()
        howl.fade(+volume.toFixed(2), 0, Math.round(duration * volume))
      }
    },
    [soundEnabled]
  )

  const fadeOutThenUnload = useCallback(
    (howl: Howl, duration?: number) => {
      if (soundEnabled && howl.playing()) {
        howl.once('fade', () => {
          howl.unload()
        })
        fadeOut(howl, duration)
      }
    },
    [soundEnabled, fadeOut]
  )

  useEffect(() => {
    Howler.mute(!soundEnabled)
  }, [soundEnabled])

  return (
    <SoundContext.Provider
      value={{
        soundEnabled,
        setSoundEnabled: (enabled: boolean) => {
          localStorage.setItem('sound', enabled.toString())
          setSoundEnabled(enabled)
        },
        newSound,
        fadeIn,
        fadeOut,
        fadeOutThenUnload,
      }}
    >
      <ParallaxProvider>
        <ThemeProvider theme={MemeManifestoTheme}>
          <AppWrapper>{children}</AppWrapper>
        </ThemeProvider>
      </ParallaxProvider>
    </SoundContext.Provider>
  )
}
