import { createGlobalStyle } from 'styled-components'
import { AppContext } from '../contexts/AppContext'
import { useIsTablet } from '../hooks/useIsTablet'

const GlobalCSS = createGlobalStyle`
  a,
  a:visited {
    color: inherit;
  }

  :root {
    --vh: 100vh;
  }
`

interface Props {
  children: React.ReactNode
}

export const AppWrapper: React.FC<Props> = ({ children }) => {
  const isTablet = useIsTablet()
  return (
    <>
      <GlobalCSS />
      <AppContext.Provider value={{ isTablet }}>{children}</AppContext.Provider>
    </>
  )
}
