import { useEffect } from 'react'
import { useWindowSize } from './useWindowSize'

export const useViewportHeight = () => {
  const { innerHeight } = useWindowSize()

  useEffect(() => {
    document.documentElement.style.setProperty('--vh', `${innerHeight}px`)
  }, [innerHeight])

  return innerHeight
}
