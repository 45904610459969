import { Howl, HowlOptions } from 'howler'
import { createContext } from 'react'

export const SoundContext = createContext<{
  // null means we don't know yet if we can enable sound
  soundEnabled: boolean | null
  setSoundEnabled: (enabled: boolean) => void
  newSound: (src: string, options?: Omit<HowlOptions, 'src'>) => Howl
  fadeIn: (howl: Howl) => void
  fadeOut: (howl: Howl, duration?: number) => void
  fadeOutThenUnload: (howl: Howl, duration?: number) => void
}>({
  soundEnabled: null,
  setSoundEnabled: () => {},
  newSound: (src, options) => new Howl({ src, ...options }),
  fadeIn: () => {},
  fadeOut: () => {},
  fadeOutThenUnload: () => {},
})
