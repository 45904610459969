import './theme.css'
import { DefaultTheme, css } from 'styled-components'

export const MemeManifestoTheme: DefaultTheme = {
  backgroundColor: 'white',
  bottomBackgroundColor: 'black',
  topTextColor: 'black',
  bottomTextColor: 'white',
  gutter: 20,
  menuHeight: 112,
  colors: {
    black: '#000000',
    white: '#f9fafb',
    blue: '#66a5de',
    level1: '#ffffff',
    level2: '#ff901c',
    level3: '#ff5c5d',
    level4: '#ff85d0',
    level5: '#fff500',
    level6: '#18ff97',
    level7: '#01c5c3',
    level8: '#985eff',
    level9: '#015aff',
    level10: '#000000',
  },
  devices: {
    desktop: 1280,
    tablet: 768,
    phone: 480,
  },
  mediaQuery: function (deviceMin, deviceMax) {
    let query = `@media only screen`
    if (deviceMin) query += ` and (min-width: ${this.devices[deviceMin]}px)`
    if (deviceMax) query += ` and (max-width: ${this.devices[deviceMax] - 1}px)`
    return query
  },
  color: function (color) {
    return this.colors[this[color]]
  },
}

export const noScrollbars = css`
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const fontSneaky = css`
  font-family: Sneaky, 'Times New Roman', Times, serif;
`

export const fontSneakyTitling = css`
  font-family: 'Sneaky Titling', Sneaky, 'Times New Roman', Times, serif;
`

export const fontImpakt = css`
  font-family: 'Impact Nieuw', Impact, Charcoal, sans-serif;
  font-weight: bold;
`

export const fontHaasGrot = css`
  font-family: 'Haas Grot Disp', Helvetica, sans-serif;
`

export const hideOnTablet = css`
  @media (max-width: ${props => props.theme.devices.desktop - 1}px) {
    display: none;
  }
`

export const hideOnDesktop = css`
  @media (min-width: ${props => props.theme.devices.desktop}px) {
    display: none;
  }
`
